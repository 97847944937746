import { createContext, useEffect } from 'react'

interface AuthContextType {
  logout: () => void
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthProvider({ children }: any) {
  useEffect(() => {
    const tokenAlreadyExists = sessionStorage.getItem('peacedb@token')
    const pathname = window.location.pathname

    if (!tokenAlreadyExists && pathname !== '/') {
      sessionStorage.setItem('peacedb@redirect', pathname)
    }

    if (!tokenAlreadyExists) {
      if (pathname !== '/') {
        window.location.href = '/'
      }
    } else {
      if (pathname === '/') {
        window.location.href = '/dashboard'
      }
    }
  }, [])

  function logout() {
    sessionStorage.removeItem('peacedb@token')
    window.location.href = '/'
  }

  return <AuthContext.Provider value={{ logout }}>{children}</AuthContext.Provider>
}
