import { Tooltip } from '@mui/material'
import './styles.css'

interface TableRowProps {
  item: ItemProps
}

interface ItemProps {
  id: number
  id_backup: number
  id_google_drive: string
  arquivo: string
  status: number
  obs: string
}

export default function TableRowFile({ item }: TableRowProps) {
  return (
    <tr
      className="table__row"
      onClick={() => window.open(`https://drive.google.com/file/d/${item.id_google_drive}/view?usp=sharing`)}
    >
      <td>{item.id}</td>
      <td>{item.arquivo}</td>
      <td align="right">{item.status === 1 ? 'OK' : 'FALHA'}</td>
      <Tooltip title={item.obs} placement="right">
        <td>{item.obs}</td>
      </Tooltip>
    </tr>
  )
}
