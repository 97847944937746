import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import { Client } from '../../pages/clients'
import ChangeStatusConfirmation from '../ChangeStatusConfirmation'

import './styles.css'

interface TableRowProps {
  client: Client
  callback: (id: number, status: number) => void
}

export default function TableRowClientControl({ client, callback }: TableRowProps) {
  return (
    <tr className="table__row__client__control">
      <td>{client.id}</td>
      <td>{client.nome}</td>
      <td align="right">{client.cnpj}</td>
      <td align="right">{client.status === 1 ? 'Ativo' : 'Desativado'}</td>
      <td align="right">
        <ChangeStatusConfirmation isEnabled={client.status} id={client.id} callback={callback} />
      </td>
    </tr>
  )
}
