import { variables } from '../variables'

import axios from 'axios'
import Cookies from 'js-cookie'

export const api = axios.create({
  baseURL: variables.API,
})

api.interceptors.response.use((response) => {
  if (response.status === 401) {
    sessionStorage.removeItem('peacedb@token')
  }

  return response
})

api.defaults.headers.common['Authorization'] = `5bcb68ed-d3b3-4e36-aa0b-dd95ac4d50a3`
api.defaults.headers.post['Content-Type'] = 'application/json'
