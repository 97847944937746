import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Login from './pages/login'
import Backups from './pages/backups'
import BackupFiles from './pages/backup-files'
import Home from './pages/home'
import Clients from './pages/clients'

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Home />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/backups/client/:clientId" element={<Backups />} />
        <Route path="/backups/:backupId/files" element={<BackupFiles />} />
      </Routes>
    </BrowserRouter>
  )
}
