import { useEffect, useState } from 'react'
import { Pagination } from '@mui/material'
import { api } from '../../services/api'

import noDocument from '../../assets/icons/no__document.svg'
import SubHeader from '../../components/SubHeader'
import TableRowClient from '../../components/TableRowClient'
import Navbar from '../../components/Navbar'

import './styles.css'

export interface Client {
  id: number
  nome: string
  cnpj: string
  ultimo_backup: string
  total_arquivos_ultimo_backup: number
  erros_ultimo_backup: number
}

export default function Home() {
  const COMPONENTS_PER_PAGE = 20

  const [clients, setClients] = useState<Client[]>([])

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[] | null>([])
  const [waitingAPIRequest, setWaitingAPIRequest] = useState(false)

  useEffect(() => {
    getClients()
    handlePaginationChange(null, 1)
  }, [])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (clients?.length) {
      setPageData(clients && clients.slice(startIndex, lastIndex))
    }
  }

  async function getClients() {
    try {
      const { data } = await api.get('/clients/summary')

      const dataSplited = String(data && data.length / COMPONENTS_PER_PAGE).split('.')

      if (dataSplited[1]) {
        setPages(Number(dataSplited[0]) + 1)
      } else {
        setPages(Number(dataSplited[0]))
      }

      setPageData(data?.slice(0, COMPONENTS_PER_PAGE) || [])
      setClients(data)
    } catch (err) {
      console.log(err)
    }
  }

  function search(term: string) {
    if (!term) {
      setPageData(clients)
      return
    }

    if (term.length > 2) {
      const filtered = clients.filter(
        (c) => c.nome.toLowerCase().includes(term.toLocaleLowerCase()) || c.cnpj.toLowerCase().includes(term)
      )
      const dataSplited = String(filtered.length / COMPONENTS_PER_PAGE).split('.')

      if (dataSplited[1]) {
        setPages(Number(dataSplited[0]) + 1)
      } else {
        setPages(Number(dataSplited[0]))
      }

      setPageData(filtered?.slice(0, COMPONENTS_PER_PAGE) || [])
    }
  }

  return (
    <div className="docs">
      <Navbar />

      <div className="container">
        <SubHeader />

        <div className="tables">
          <div className="main box">
            <div className="content">
              <div className="actions">
                <form>
                  <div className="input">
                    <input
                      placeholder="pesquisar cliente"
                      type="text"
                      id="client"
                      name="client"
                      onChange={(e) => search(e.target.value)}
                    />
                  </div>
                </form>
              </div>

              {waitingAPIRequest ? (
                <div className="loading"></div>
              ) : pageData?.length ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th align="left">CLIENTE</th>
                        <th align="right">ÚLTIMO BACKUP</th>
                      </tr>
                    </thead>

                    <tbody>
                      {pageData.map((client) => (
                        <TableRowClient key={client.id} client={client} />
                      ))}
                    </tbody>
                  </table>

                  <div className="table__footer">
                    {clients && clients.length > 5 ? (
                      <Pagination
                        count={pages}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePaginationChange}
                      />
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="table__warning">
                  <img src={noDocument} alt="no-document" />
                  <h1>
                    não encontramos clients <br /> para listar <br />
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
