import ReactDOM from 'react-dom/client'
import Router from './router'

import './index.css'

import { TableProvider } from './contexts/TableContext'
import { AuthProvider } from './contexts/AuthContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <AuthProvider>
    <TableProvider>
      <Router />
    </TableProvider>
  </AuthProvider>
)
