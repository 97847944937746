import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft'

import './styles.css'

export default function SubHeader() {
  const pathname = window.location.pathname

  return (
    <div className="client">
      {pathname !== '/dashboard' && (
        <div className="back__menu" onClick={() => window.history.back()}>
          <ArrowLeft />
          <span>voltar </span>
        </div>
      )}
    </div>
  )
}
