import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import './styles.css'

type ChangeStatusConfirmationProps = {
  id: number
  isEnabled: number
  callback: (id: number, status: number) => void
}

export default function ChangeStatusConfirmation({ id, isEnabled, callback }: ChangeStatusConfirmationProps) {
  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger asChild>
        {/* {children} */}
        {isEnabled === 1 ? (
          <div className="active__button">
            <CheckIcon height="22" width="22" color="white" />
          </div>
        ) : (
          <div className="inactive__button">
            <Cross2Icon height="22" width="22" color="white" />
          </div>
        )}
      </AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="AlertDialogOverlay" />
        <AlertDialog.Content className="AlertDialogContent">
          <AlertDialog.Title className="AlertDialogTitle">
            Deseja {isEnabled ? 'desabilitar' : 'habilitar'} esse registro?
          </AlertDialog.Title>
          <AlertDialog.Description className="AlertDialogDescription">
            {isEnabled
              ? 'Você tem certeza? Esse registro não terá mais permissões nas plataformas vinculadas.'
              : 'Você tem certeza? Esse registro terá permissões nas plataformas vinculadas.'}
          </AlertDialog.Description>
          <div style={{ display: 'flex', gap: 15, justifyContent: 'flex-end', paddingTop: 15 }}>
            <AlertDialog.Cancel asChild>
              <button className="Button red">Cancelar</button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <button className="Button green" onClick={() => callback(id, isEnabled ? 0 : 1)}>
                Sim, {isEnabled ? 'desativar' : 'ativar'}
              </button>
            </AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
