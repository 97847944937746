import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { CaretDownIcon } from '@radix-ui/react-icons'
import { ExitIcon } from '@radix-ui/react-icons'

import './styles.css'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

export default function Navbar() {
  const { logout } = useContext(AuthContext)

  return (
    <NavigationMenu.Root className="navigation__menu__root">
      <NavigationMenu.List className="navigation__menu__list">
        <NavigationMenu.Item>
          <NavigationMenu.Link className="navigation__menu__link" href="/dashboard">
            Home
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Trigger className="navigation__menu__trigger">
            Gerenciamento <CaretDownIcon className="CaretDown" aria-hidden />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="navigation__menu__content">
            <ul className="list two">
              <li>
                <NavigationMenu.Link asChild>
                  <a className="list__item__link" href="/clients">
                    <div className="list__item__heading">Clientes</div>
                    <p className="list__item__text">Gerencie o status dos clientes cadastrados.</p>
                  </a>
                </NavigationMenu.Link>
              </li>
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link className="navigation__menu__link" onClick={() => logout()}>
            <ExitIcon />
          </NavigationMenu.Link>
        </NavigationMenu.Item>
      </NavigationMenu.List>

      <div className="viewport__position">
        <NavigationMenu.Viewport className="navigation__menu__viewport" />
      </div>
    </NavigationMenu.Root>
  )
}
